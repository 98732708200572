
import web from '@/api/web/co.web'

const Names = {
  PROCESSING: 'processing',
  PROCESSED: 'processed'
}

// const ProcessingStates = [
//   web.comm.ActivityStates.PUBLISH,
//   web.comm.ActivityStates.DOING
// ]

const CatProcessing = {
  title: '进行中',
  name: Names.PROCESSING,
  href: '',
  type: '',
  state: web.comm.ActivityStates.PUBLISH,
  doingDate: '',
  finishDate: '',
  ratio: 1.85,
  margin: 'ma-4',
  tooled: false,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有发布活动',
  pagination: {
    current: 1,
    length: 1,
    totalVisible: 7
  },
}

const CatProcessed = {
  title: '往期活动',
  name: Names.PROCESSED,
  href: '',
  type: '',
  state: web.comm.ActivityStates.PUBLISH,
  doingDate: '',
  finishDate: '',
  ratio: 1.85,
  margin: 'ma-4',
  tooled: false,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有往期活动',
  pagination: {
    current: 1,
    length: 1,
    totalVisible: 7
  },
}

const DetailNames = {
  BRIEF: 'brief',
  WORKS: 'works',
  CHOSEN: 'chosen'
}

const PartBrief = {
  title: '活动简介',
  name: DetailNames.BRIEF,
}

const PartWorks = {
  title: '全部作品',
  name: DetailNames.WORKS,
  type: '',
  state: '',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: false,
  navigated: false,
  params: null,
  items: [],
  empty: '暂无投稿作品',
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const PartChosen = {
  title: '入选作品',
  name: DetailNames.CHOSEN,
  type: '',
  state: '',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: false,
  navigated: false,
  params: null,
  items: [],
  empty: '暂无入选作品',
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const myPreset = {
  Names,
  DetailNames,
  Parts: {
    [Names.PROCESSING]: CatProcessing,
    [Names.PROCESSED]: CatProcessed
  },
  DetailParts: {
    [DetailNames.BRIEF]: PartBrief,
    [DetailNames.WORKS]: PartWorks,
    [DetailNames.CHOSEN]: PartChosen
  }
}

export default myPreset
