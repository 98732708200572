<template>
  <land-gallery
    :items="tab.items"
    :type="tab.type"
    :ratio="tab.ratio"
    :tooled="tab.tooled"
    margin="ma-2"
    back-color="blue-grey-lighten-5"
    per-columns="1"
    @click="onContent($event, tab)"
  />
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/activity/mix.activity.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names

  export default {
    name: 'SectionIssueCenterActivity',
    data () {
      return {
        tab: parts[names.PROCESSING],
      }
    },
    created () {
      this.loadContent()
    },
    methods: {
      onContent (ev, navigation) {
        const item = ev.item
        this.toContent(item, navigation)
      },
      toContent (item, navigation) {
        const params = {
          activityId: item.activityId,
          // title: item.title,
          // name: navigation.name,
        }
        api.page.navigateBy(this, 'activityDetail', params)
      },
      loadContent () {
        const obj = this.tab
        const executed = function (res) {
          // console.log('me.dataList: %o', me.dataList)
          if (res.status) {
          }
        }

        obj.params = web.activity.getParams({
          type: obj.type,
          state: obj.state,
          pageSize: '3',
          caches: obj.items,
          executed
        })

        obj.params.reload = true
        api.httpx.getItems(obj.params)
      }
    }
  }
</script>
